<template>
  <div class="">
    <FAQsList/>
  </div>
</template>
<script>
import FAQsList from "@/views/components/FAQs/FAQsList";
import GoodTableSsr from "@/views/table/vue-good-table/GoodTableSsr";
import '@core/scss/vue/libs/vue-good-table.scss';
export default {
  name: "Index",
  components: {
    FAQsList,
    GoodTableSsr
  }
}
</script>

<style scoped lang="scss">

</style>
