<template>
  <b-card title="FAQs Table">
    <div
      class="
        custom-search
        d-flex
        justify-content-end justify-content-sm-start
        mb-2 mb-sm-0
      "
    >
      <div class="d-flex align-items-center">
        <b-button
          id="show-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="float-right"
          variant="primary"
          :to="{ name: 'create-FAQs' }"
          >Add FAQs</b-button
        >
      </div>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="params.query"
            placeholder="Search"
            type="text"
            class="d-inline-block"
            debounce="1500"
          />
        </div>
      </b-form-group>
    </div>
    <Modal
      :text="modalOptions.text"
      :ok-button="modalOptions.okButton"
      :cancel-button="modalOptions.cancelButton"
      :show="show"
      @result="modalOptions.modalResult"
      @hidden="show = false"
    />
    <!-- table -->
    <vue-good-table
      mode="remote"
      :pagination-options="{
        enabled: true,
      }"
      :totalRows="totalRecords"
      :rows="rows"
      :columns="columns"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange"
      :isLoading.sync="isLoading"
    >
      <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field == 'PolicyEn'" class="d-flex">
          <label class="ml-1" v-html="props.row.policyEn"></label>
        </span>

        <span v-if="props.column.field == 'PolicyFr'" class="d-flex">
          <label class="ml-1" v-html="props.row.policyFr"></label>
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'update-FAQs', params: { id: props.row._id } }"
              >
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Edit
                </span>
              </b-dropdown-item>

              <b-dropdown-item @click="showRemovePopup(props.row)">
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Delete
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{
            isOPen ? "Loading..." : "This will show up when there are no rows"
          }}
        </p>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BButton,
  BCardTitle
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import Modal from "@/views/components/shared/Modal/Modal";
import FAQsService from "@/services/FAQsService";

export default {
  name: "ChatList",
  mixins: [],
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BCardTitle,
    BButton,
    Modal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      params: {
        page: 1,
        limit: 10,
        sort_keys: undefined,
        sort_dir: undefined,
        query: undefined,
      },
      columns: [
        { label: "Question En", field: "questionEn" },
        { label: "Answer En", field: "answerEn" },
        { label: "Question Fr", field: "questionFr" },
        { label: "Answer En", field: "answerFr" },
        { label: "Action", field: "action" },
      ],
      rows: [],
      isLoading: undefined,
      isOPen: true,
      totalRecords: undefined,
      faqsService: new FAQsService(),
      show: false,
      modalOptions: {
        modalResult: this.modalResultRemove,
      },
    };
  },
  watch: {
    params: {
      handler: function () {
        this.getFAQs();
      },
      deep: true,
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getFAQs();
  },
  methods: {
    onPerPageChange(page) {
      this.params.page = 1;
      this.params.limit = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.limit = active.currentPerPage;
    },
    onSortChange(params) {
      const SortKey =
        typeof params[0].field === "function" ? "client" : params[0].field;
      this.params.sort_keys = [SortKey[0].toLowerCase() + SortKey.slice(1)];
      this.params.sort_dir = [params[0].type];
    },
    getFAQs() {
      this.isLoading = true;
      this.faqsService
        .index(this.params)
        .then((res) => {
          this.rows = res.data.map((el) => {
            return {
              ...el,
            };
          });
          this.totalRecords = res.data.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
          this.isOPen = false;
        });
    },
    showRemovePopup(model) {
      this.show = true;
      this.show = true;
      this.model = model;
      this.modalOptions = {
        text: `Are you sure you want to delete this chat`,
        okButton: `Delete`,
        cancelButton: "Cancel",
        clickedId: model._id,
        currentAction: "Block",
        modalResult: this.modalResultRemove,
      };
    },
    modalResultRemove(value) {
      this.show = false;
      if (value) {
        this.deleteFAQ(this.model._id);
      }
    },
    deleteFAQ(id) {
      this.isLoading = true;
      this.faqsService
        .delete(id)
        .then((res) => {
          this.$router.go();
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
.first {
  background-color: #fce19eae;
}

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}
@media (max-width: 576px) {
  th,
  td {
    /* font size */
    font-size: 0.857rem;
    font-weight: 400;
    /* text center horizontally */
    vertical-align: middle !important;
    text-align: center;
    line-height: 20px;
  }
}
</style>